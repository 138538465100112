import { useMainStore } from '../../store/store';

export const useFormButton = () => {
  const { step, phoneErr, countErr, phone, count, code, fullName, fullNameErr, selectedDate } =
    useMainStore();

  const getButtonText = () => {
    switch (step) {
      case 0:
        return 'Выбрать дату монтажа';
      case 1:
        return 'Подтвердить дату монтажа';
      default:
        return 'Отправить';
    }
  };

  const getButtonDisabled = () => {
    switch (step) {
      case 0:
        return !(fullName && phone && count && !fullNameErr && !phoneErr && !countErr);
      case 1:
        return !(
          fullName &&
          phone &&
          count &&
          !fullNameErr &&
          !phoneErr &&
          !countErr &&
          selectedDate
        );

      default:
        return !(
          fullName &&
          phone &&
          count &&
          !fullNameErr &&
          !phoneErr &&
          !countErr &&
          selectedDate &&
          code
        );
    }
  };

  return {
    buttonText: getButtonText(),
    buttonDisabled: getButtonDisabled(),
  };
};
