import { endOfMonth, format, isSameDay, startOfMonth } from 'date-fns';

export const toFormateDate = (date?: Date | null): string => {
  if (!date) return '';
  return format(date, 'dd.MM.yyyy');
};

// Проверка на доступность даты
export const isDateDisabled = (date: Date, availableDates: Date[]) => {
  return !availableDates.some((availableDate) => isSameDay(date, availableDate));
};

export const hasAvailableDatesInMonth = (
  direction: 'left' | 'right',
  selectedMonth: Date,
  availableDates: Date[]
) => {
  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(selectedMonth);

  if (direction === 'right') {
    // Проверяем, есть ли даты больше конца текущего месяца
    return availableDates.some((date) => date > monthEnd);
  }

  if (direction === 'left') {
    // Проверяем, есть ли даты меньше начала текущего месяца
    return availableDates.some((date) => date < monthStart);
  }

  return false; // На всякий случай, если direction некорректный
};
