import { EndText, ComponentType } from '../components/EndText';
import { clearPhoneNumber } from '../utils/clearString';
import { createStore } from '../utils/createStore';
import { errorsHandler } from '../utils/errorsHandler';

interface IMainStore {
  step: number;
  setNextStep: () => void;
  setEndStep: () => void;
  clearStep: () => void;
  setStep: (step: number) => void;

  endText?: JSX.Element;
  setEndText: (endText: JSX.Element) => void;

  fullName?: string;
  phone?: string;
  count?: string;
  code?: string;
  selectedDate: Date | null;
  selectedMonth: Date;
  availableDates: Date[];
  isSubmitting: boolean;

  fullNameErr?: string;
  phoneErr?: string;
  countErr?: string;
  codeErr?: string;

  setCodeErr: () => void;
  clearCodeErr: () => void;

  clearFullName: () => void;
  clearPhone: () => void;
  clearCount: () => void;
  clearCode: () => void;

  setFullName: (fullName: string) => void;
  setPhone: (str: string) => void;
  setCount: (str: string) => void;
  setCode: (str: string) => void;
  setSelectedDate: (selectedDate: Date | null) => void;
  setSelectedMonth: (selectedMonth: Date) => void;
  setAvailableDates: (availableDates: Date[]) => void;

  setIsSubmitting: (isSubmitting: boolean) => void;
  catchErrorWithClear: (error: any) => void;
}

export const useMainStore = createStore<IMainStore>(
  (set, get) => ({
    step: 0,
    fullName: '',
    isSubmitting: false,
    selectedDate: null,
    selectedMonth: new Date(),
    availableDates: [],
    setNextStep: () => set(() => ({ step: get().step + 1 })),
    setEndStep: () => set(() => ({ step: 6 })),
    clearStep: () => set(() => ({ step: 0 })),
    setStep: (step) => set(() => ({ step })),
    setSelectedDate: (selectedDate) => set(() => ({ selectedDate })),
    setSelectedMonth: (selectedMonth) => set(() => ({ selectedMonth })),
    setAvailableDates: (availableDates) => set(() => ({ availableDates })),
    setIsSubmitting: (isSubmitting) => set(() => ({ isSubmitting })),

    setEndText: (endText) => set(() => ({ endText })),

    clearFullName: () => set(() => ({ fullName: '' })),
    clearPhone: () => set(() => ({ phone: undefined })),
    clearCount: () => set(() => ({ count: undefined })),
    clearCode: () => set(() => ({ code: undefined })),

    setFullName: (fullName) => {
      const backendRegexp = /(?=^(?:[А-Яа-яЁё]{1,20}[ -]){1,}[А-Яа-яЁё]{1,20}$)^[А-Яа-яЁё -]+$/;
      const onlyKyrilicSymbols = /^[a-zA-Zа-яА-ЯёЁ -]+$/.test(fullName);
      if (onlyKyrilicSymbols || fullName === '') {
        set(() => ({
          fullName,
          fullNameErr: backendRegexp.test(fullName)
            ? undefined
            : 'Введите ФИО в формате "Иванов Иван Иванович"',
        }));
      }
    },

    setPhone: (str) =>
      set(() => ({
        phone: clearPhoneNumber(str),
        phoneErr: clearPhoneNumber(str).length < 11 ? 'Введите номер целиком' : undefined,
      })),

    setCount: (str) => {
      const onlyAllowedSymbols = /^[0-9-]+$/.test(str);
      if (onlyAllowedSymbols || str === '') {
        set(() => ({
          count: str,
          countErr: str.length < 7 ? 'Лицевой счет должен содержать минимум 7 символов' : undefined,
        }));
      }
    },
    setCode: (str) => set(() => ({ code: str })),

    setCodeErr: () => set(() => ({ codeErr: 'Введен неверный код' })),
    clearCodeErr: () => set(() => ({ codeErr: undefined })),

    catchErrorWithClear: (error) => {
      get().clearCount();
      get().clearPhone();
      get().clearFullName();
      get().clearCode();
      get().setEndStep();
      get().setEndText(
        <EndText
          type={ComponentType.ERROR}
          errorText={errorsHandler(error?.response)}
        />
      );
    },
  }),
  'data'
);
