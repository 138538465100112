import React, { FC } from 'react';
import styles from './endText.module.css';
import { useResize } from '../../hooks/useResize';

interface IProps {
  type: ComponentType;
  countNumber?: string;
  errorText?: string;
}

export enum ComponentType {
  OK,
  DUPLICATE,
  ERROR,
  NOTFOUND,
}

export const EndText: FC<IProps> = ({ countNumber, type, errorText }) => {
  const { isScreenSm } = useResize();

  switch (type) {
    case ComponentType.OK:
      return (
        <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
          <h2>Спасибо за заявку!</h2>

          <p>
            В данный момент заявка находится в обработке. Наш оператор свяжется с вами, чтобы
            подтвердить дату и время замены.
          </p>

          <p>
            В случае возникновения вопросов, пожалуйста, свяжитесь с нами{' '}
            <a href='tel:88007757840'>8&nbsp;800&nbsp;775&nbsp;78&nbsp;40</a>.
          </p>
        </div>
      );
    case ComponentType.DUPLICATE:
      return (
        <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
          <h2>Вы уже подавали заявку ранее</h2>
          <p>
            Ваша заявка находится в работе. В скором времени с вами свяжется оператор для уточнения
            даты
          </p>
          <p>По всем вопросам вы можете позвонить в Колл-Центр</p>
          <div className={styles.phone}>8 800 775 78 40</div>
        </div>
      );
    case ComponentType.NOTFOUND:
      return (
        <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
          <h2>Ваш лицевой счет не найден в базе</h2>
          <p>Пожалуйста, свяжитесь с оператором для уточнения информации и оформления заявки </p>
          <div className={styles.phone}>8 800 775 78 40</div>
        </div>
      );
    case ComponentType.ERROR:
    default:
      return (
        <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
          <h2>{errorText}</h2>
          <p>Пожалуйста, свяжитесь с оператором для уточнения информации и оформления заявки </p>
          <div className={styles.phone}>8 800 775 78 40</div>
        </div>
      );
  }
};
