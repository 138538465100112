import React, { FC } from 'react';
import styles from './form.module.css';
import { useResize } from '../../hooks/useResize';
import { Input } from '../Input';
import { useMainStore } from '../../store/store';
import { consumerClaim, sendRequestToCallcenter, sendSmsToPhone } from '../../api/api';
import { errorsHandler } from '../../utils/errorsHandler';
import { clearPhoneNumber } from '../../utils/clearString';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { ComponentType, EndText } from '../EndText';
import { Counter } from '../Counter';
import DatePicker from '../DatePicker/DatePicker';
import { useFormButton } from './utils';
import { LoadingButton } from '@mui/lab';
import { toFormateDate } from '../DatePicker/utils';
import { FormLoadingButton } from './Form.styled';

interface IProps {}

export const Form: FC<IProps> = () => {
  const { isScreenSm, isScreenLg } = useResize();
  const {
    endText,
    setEndText,
    setEndStep,
    step,
    setNextStep,
    clearStep,
    phoneErr,
    codeErr,
    countErr,
    phone,
    count,
    code,
    fullName,
    fullNameErr,
    clearFullName,
    clearCount,
    clearCode,
    setCode,
    setCount,
    setPhone,
    clearPhone,
    setCodeErr,
    clearCodeErr,
    setFullName,
    setStep,
    isSubmitting,
    setIsSubmitting,
    selectedDate,
    catchErrorWithClear,
  } = useMainStore();

  const { buttonText, buttonDisabled } = useFormButton();

  const sendSms = () => {
    if (!fullName || !phone || !count || fullNameErr || phoneErr || countErr) return;

    setIsSubmitting(true);
    sendSmsToPhone(phone)
      .then(() => setStep(2))
      .catch(catchErrorWithClear)
      .finally(() => setIsSubmitting(false));
  };

  const sendCode = () => {
    if (
      !fullName ||
      !phone ||
      !count ||
      !code ||
      !selectedDate ||
      fullNameErr ||
      phoneErr ||
      countErr ||
      codeErr
    ) {
      return;
    }

    setIsSubmitting(true);
    sendRequestToCallcenter(
      fullName,
      clearPhoneNumber(phone),
      count,
      code,
      toFormateDate(selectedDate)
    )
      .then((res) => {
        clearCount();
        clearPhone();
        clearFullName();
        clearCode();
        return consumerClaim(res.data.consumerClaimRequestId);
      })
      .then((res) => {
        setStep(6);
        setEndText(
          <EndText
            type={ComponentType.OK}
            countNumber={`${res.data}`}
          />
        );
      })
      .catch((err) => {
        if (err?.response?.status == '403' && step < 4) {
          setNextStep();
          setCodeErr();
        } else {
          catchErrorWithClear(err);
        }
      })
      .finally(() => {
        clearCode();
        setIsSubmitting(false);
      });
  };

  const onClick = () => {
    switch (step) {
      case 0:
        setStep(1);
        break;
      case 1:
        sendSms();
        break;
      default:
        sendCode();
        break;
    }
  };

  return (
    <>
      {step < 6 && (
        <div className={`${styles.baner} ${isScreenSm ? styles.mobile : ''}`}>
          <div
            className={`${styles.wrapper} ${isScreenLg ? styles.lg : ''} ${
              isScreenSm ? styles.mobile : ''
            }`}
          >
            <>
              <Input
                label={'Ф.И.О.'}
                mask={''}
                value={fullName}
                onChange={setFullName}
                error={fullNameErr}
                disabled={step >= 2}
              />

              <Input
                label={'Номер телефона'}
                mask={'+7 (999) 999 99 99'}
                value={phone}
                onChange={setPhone}
                error={phoneErr}
                disabled={step >= 2}
              />
              <Input
                label={'Лицевой счет'}
                mask={''}
                value={count}
                onChange={setCount}
                error={countErr}
                maxLength={12}
                disabled={step >= 1}
              />

              {step !== 0 && <DatePicker />}

              {(step === 2 || step === 3 || step === 4) && (
                <Input
                  label={'Код из СМС'}
                  value={code}
                  onChange={(e) => {
                    setCode(e);
                    clearCodeErr();
                  }}
                  mask='9999'
                  error={codeErr}
                />
              )}

              <div {...(step === 1 && { style: { marginTop: isScreenSm ? '-21px' : '-24px' } })}>
                <div
                  className={`${styles.forget} ${isScreenSm ? styles.mobile : ''}`}
                  onClick={() => {
                    setEndStep();
                    setEndText(<EndText type={ComponentType.ERROR} />);
                    clearCount();
                    clearPhone();
                    clearFullName();
                  }}
                >
                  Я не помню свой лицевой счет
                </div>
                <p className={`${styles.persData} ${isScreenSm ? styles.mobile : ''}`}>
                  Нажимая кнопку, вы принимаете{' '}
                  <a
                    href='/files/Соглашение_на_обработку_ПД.pdf'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Согласие на обработку персональных данных
                  </a>
                </p>
              </div>

              <FormLoadingButton
                variant='contained'
                onClick={onClick}
                disabled={buttonDisabled}
                loading={isSubmitting}
                isScreenSm={isScreenSm}
              >
                {buttonText}
              </FormLoadingButton>
            </>
          </div>

          {!isScreenLg && <Counter />}
        </div>
      )}

      {step === 6 && (
        <div className={`${styles.endTextWrapper} ${isScreenSm ? styles.mobile : ''}`}>
          <ArrowBackIosNewRoundedIcon
            fontSize='large'
            style={{ color: '#3B91CB', cursor: 'pointer', marginBottom: 10 }}
            onClick={() => {
              clearCount();
              clearCode();
              clearPhone();
              clearFullName();
              clearStep();
            }}
          />
          <div>{endText}</div>
        </div>
      )}
    </>
  );
};
