import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

interface FormLoadingButtonProps {
  isScreenSm?: boolean;
}
export const FormLoadingButton = styled(LoadingButton)<FormLoadingButtonProps>(
  ({ isScreenSm }) => ({
    fontSize: isScreenSm ? 14 : 16,
    background: '#3b91cb',
    fontFamily: "'Roboto Reg'",
    padding: '13px',
    lineHeight: '19px',
    letterSpacing: '0.05em',
  })
);
