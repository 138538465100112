import { useEffect, useState } from 'react';
import { useMainStore } from '../../store/store';
import { useResize } from '../../hooks/useResize';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ru } from 'date-fns/locale';
import { Badge, CircularProgress, IconButton } from '@mui/material';
import { parse } from 'date-fns';
import { getAvaliableDates } from '../../api/api';
import { shallow } from 'zustand/shallow';
import {
  DatePickerBackdrop,
  DatePickerSeccionEndedLink,
  DatePickerTitle,
  DatePickerWrapper,
} from './DatePicker.styled';
import React from 'react';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { EndText, ComponentType } from '../EndText';
import { hasAvailableDatesInMonth, isDateDisabled } from './utils';
import { useSnackbar } from '../Snackbar/Snackbar';

const DatePicker = () => {
  const showSnackbar = useSnackbar();
  const handleSessionEnd = () => {
    showSnackbar(
      <>
        Ваша сессия истекла. Пожалуйста,&nbsp;
        <DatePickerSeccionEndedLink onClick={() => window.location.reload()}>
          перезагрузите
        </DatePickerSeccionEndedLink>
        &nbsp;страницу
      </>,
      'error'
    );
  };
  const handleDateDisabled = () => {
    setSelectedDate(null);
    showSnackbar(
      'Выбранная вами дата стала недоступной из за достижения предела кол-ва заявок на дату. Пожалуйста, выберите другую дату',
      'error',
      5000
    );
  };

  const { isScreenSm } = useResize();

  const {
    setSelectedDate,
    selectedDate,
    count,
    selectedMonth,
    setSelectedMonth,
    setEndText,
    setStep,
    catchErrorWithClear,
  } = useMainStore(
    (state) => ({
      setSelectedDate: state.setSelectedDate,
      selectedDate: state.selectedDate,
      step: state.step,
      count: state.count,
      selectedMonth: state.selectedMonth,
      setSelectedMonth: state.setSelectedMonth,
      setEndText: state.setEndText,
      setStep: state.setStep,
      catchErrorWithClear: state.catchErrorWithClear,
    }),
    shallow
  );

  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [isLoadingAvaliableDates, setIsLoadingAvaliableDates] = useState(false);

  useEffect(() => {
    fetchAvailableDates();

    // Перезапрос каждые 10 секунд
    const intervalId = setInterval(fetchAvailableDates, 10000);

    // Завершение сессии через 10 минут
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      handleSessionEnd();
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  const fetchAvailableDates = () => {
    if (!count) return;
    setIsLoadingAvaliableDates(true);

    getAvaliableDates(count)
      .then(({ data }) => {
        const prepareData = data.map((date) => parse(date, 'dd.MM.yyyy', new Date()));
        if (!prepareData.length) {
          setStep(6);
          setEndText(
            <EndText
              type={ComponentType.ERROR}
              errorText='Доступные даты монтажа не найдены'
            />
          );
          return;
        }

        setAvailableDates(prepareData);

        const currentSelectedDate = useMainStore.getState().selectedDate;
        const isDateDisabled =
          currentSelectedDate &&
          !prepareData.some((d) => d.getTime() === currentSelectedDate.getTime());
        if (isDateDisabled) {
          handleDateDisabled();
        }
      })
      .catch(catchErrorWithClear)
      .finally(() => {
        setIsLoadingAvaliableDates(false);
      });
  };

  const handleMonthChange = (date: Date) => {
    setSelectedDate(null);
    setSelectedMonth(date);
  };

  const ArrowButton = (direction: 'left' | 'right') => (props: any) => {
    const hasAvailableDates = hasAvailableDatesInMonth(direction, selectedMonth, availableDates);
    const Icon =
      direction === 'left' ? KeyboardArrowLeftRoundedIcon : KeyboardArrowRightRoundedIcon;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {direction === 'left' && isLoadingAvaliableDates && <CircularProgress size={15} />}

        <Badge
          color='info'
          variant='dot'
          invisible={!hasAvailableDates}
          overlap='circular'
        >
          <IconButton {...props}>
            <Icon />
          </IconButton>
        </Badge>
      </div>
    );
  };

  return (
    <DatePickerWrapper>
      <DatePickerTitle isScreenSm={isScreenSm}>
        Вы можете выбрать желаемую дату монтажа из доступных.
        <br />
        Оператор позже свяжется с Вами для подтверждения даты
      </DatePickerTitle>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ru}
      >
        <div style={{ position: 'relative', width: 'fit-content' }}>
          <CalendarPicker
            date={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            shouldDisableDate={(date) => isDateDisabled(date, availableDates)}
            onMonthChange={handleMonthChange}
            disablePast
            views={['day']}
            components={{
              LeftArrowButton: ArrowButton('left'),
              RightArrowButton: ArrowButton('right'),
            }}
          />
        </div>
      </LocalizationProvider>
    </DatePickerWrapper>
  );
};

export default React.memo(DatePicker);
