import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, Button, Slide, Typography } from '@mui/material';

interface SnackbarContextType {
  (
    message: React.ReactNode,
    severity?: 'success' | 'info' | 'warning' | 'error',
    autoHideDuration?: number | null
  ): void;
}

interface SnackbarState {
  open: boolean;
  message: React.ReactNode;
  severity: 'success' | 'info' | 'warning' | 'error';
  autoHideDuration?: number | null;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: <></>,
    severity: 'info',
  });

  const showSnackbar: SnackbarContextType = (
    message,
    severity = 'info',
    autoHideDuration = null
  ) => {
    setSnackbar((prev) => ({ ...prev, open: false }));
    setTimeout(() => {
      setSnackbar({ open: true, message, severity, autoHideDuration });
    }, 300);
  };

  const handleClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.severity}
          sx={{ width: '100%', alignItems: 'center' }}
        >
          <Typography>{snackbar.message}</Typography>
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
