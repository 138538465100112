import React from 'react';
import './App.css';
import { Header } from './components/Header';
import { Screen } from './components/Screen';
import { Baner } from './components/Baner';
import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { SnackbarProvider } from './components/Snackbar/Snackbar';

function App() {
  return (
    <SnackbarProvider>
      <div className='main_wrapper'>
        <Header />
        <Baner />
        <Form />
        <Screen />
        <Footer />
      </div>
    </SnackbarProvider>
  );
}

export default App;
