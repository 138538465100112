import { Backdrop, styled } from '@mui/material';

export const DatePickerWrapper = styled('div')({
  position: 'relative',

  '.MuiCalendarPicker-root': {
    margin: '0',
    width: '290px',
  },

  '.MuiPickersCalendarHeader-label': {
    textTransform: 'capitalize',
    fontFamily: `'Inter Reg'`,
  },
  '.MuiPickersCalendarHeader-root': {
    paddingLeft: '0',
  },
  '.MuiDayPicker-header': {
    justifyContent: 'flex-start',
  },
  '.MuiDayPicker-weekContainer': {
    justifyContent: 'flex-start',
  },
});

export interface DatePickerTitleProps {
  isScreenSm?: boolean;
}
export const DatePickerTitle = styled('div')<DatePickerTitleProps>(({ isScreenSm }) => ({
  fontSize: isScreenSm ? 14 : 20,
  marginBottom: '20px',
  fontFamily: 'Inter Reg',
}));

export const DatePickerBackdrop = styled(Backdrop)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
});

export const DatePickerSeccionEndedLink = styled('span')({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: 'blue',
});
